<template>
  <div>
    <Banner :data="data" class="mb-10" />
    <AboutMe :data="data" class="mb-10" />
  </div>
</template>

<script>
import Banner from '@/components/summary/Banner'
import AboutMe from '@/components/summary/AboutMe'

export default {
  components: {
    Banner,
    AboutMe
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
