<template>
  <div class="lg:container mx-auto">
    <div class="grid grid-cols-2 gap-4">
      <div>
        <div class="text-3xl mb-5">
          <span class="border-b-2  border-gray-200 pb-1">Acerca de mi</span>
        </div>
        <div v-html="data.aboutMe.biography" />
      </div>

      <div>
        <div class="text-3xl mb-5">
          <span class="border-b-2  border-gray-200 pb-1"
            >Informacion personal</span
          >
        </div>
        <div class="mb-4">
          <span class="font-medium">Nombre:</span> {{ data.aboutMe.name }}
        </div>
        <div class="mb-4">
          <span class="font-medium">Apellidos:</span>
          {{ data.aboutMe.lastNames }}
        </div>
        <div class="mb-4">
          <span class="font-medium">Fecha de nacimieto:</span>
          {{ data.aboutMe.dateOfBirth }}
        </div>
        <div class="mb-4">
          <span class="font-medium">Direccion:</span> {{ data.aboutMe.address }}
        </div>
        <div class="mb-4">
          <span class="font-medium">Email:</span> {{ data.email }}
        </div>
        <div class="mb-4">
          <span class="font-medium">Telefono:</span> {{ data.aboutMe.phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Card from '@/components/Card'
export default {
  components: {
    //Card
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style></style>
