<template>
  <div
    class="h-screen  w-sceen bg-black flex flex-col items-center justify-center justify-items-center relative"
  >
    <div class="absolute h-full w-full opacity-50">
      <img
        class="h-full w-full object-cover"
        :src="data.banner.backgroundImage"
      />
    </div>
    <div
      class="relative flex flex-col items-center justify-between justify-items-center h-full"
    >
      <div />
      <div
        class="flex flex-col items-center justify-center justify-items-center"
      >
        <img
          v-if="data.banner.showPhoto && data.aboutMe.photo"
          class="rounded-full border-4 border-white mb-4 w-36 h-36  md:mb-8"
          alt=""
          :src="data.aboutMe.photo"
        />
        <div
          class="text-white text-3xl md:text-4xl lg:text-5xl font-semibold mb-4 md:mb-8 "
        >
          {{ data.banner.title }}
        </div>
        <div
          class="text-white text-xl md:text-2xl lg:text-3xl font-normal mb-4 md:mb-8"
        >
          {{ data.banner.subTitle }}
        </div>

        <div
          v-if="data.banner.social"
          class="flex items-center justify-center justify-items-center  border-t-2 pt-6  md:pt-8 border-gray-200 border-opacity-50"
        >
          <a
            v-if="data.socialNetworks.linkedin"
            class="mr-4 w-6 flex items-center justify-center justify-items-center"
            :href="data.socialNetworks.linkedin"
            target="_blank"
          >
            <Linkedin class="w-full" color="#fff" />
          </a>
          <a
            v-if="data.socialNetworks.facebook"
            class="mr-4 w-6 flex items-center justify-center justify-items-center"
            :href="data.socialNetworks.facebook"
            target="_blank"
          >
            <Facebook class="w-full" color="#fff" />
          </a>
          <a
            v-if="data.socialNetworks.twitter"
            class="mr-4 w-6 flex items-center justify-center justify-items-center"
            :href="data.socialNetworks.twitter"
            target="_blank"
          >
            <Twitter class="w-full" color="#fff" />
          </a>
          <a
            v-if="data.socialNetworks.instagram"
            class="mr-4 w-6 flex items-center justify-center justify-items-center"
            :href="data.socialNetworks.instagram"
            target="_blank"
          >
            <Instagram class="w-full" color="#fff" />
          </a>
          <a
            v-if="data.socialNetworks.pinterest"
            class="mr-4 w-6 flex items-center justify-center justify-items-center"
            :href="data.socialNetworks.pinterest"
            target="_blank"
          >
            <Pinterest class="w-full" color="#fff" />
          </a>
          <a
            v-if="data.socialNetworks.youtube"
            class="mr-4 w-6 flex items-center justify-center justify-items-center"
            :href="data.socialNetworks.pinterest"
            target="_blank"
          >
            <Youtube class="w-full" color="#fff" />
          </a>
          <a
            v-if="data.socialNetworks.github"
            class="mr-4 w-6 flex items-center justify-center justify-items-center"
            :href="data.socialNetworks.github"
            target="_blank"
          >
            <Github class="w-full" color="#fff" />
          </a>
          <a
            v-if="data.socialNetworks.gitlab"
            :href="data.socialNetworks.gitlab"
            class="mr-4 w-6 flex items-center justify-center justify-items-center"
            target="_blank"
          >
            <Gitlab class="w-full" color="#fff" />
          </a>
          <a
            v-if="data.socialNetworks.patreon"
            :href="data.socialNetworks.patreon"
            class="mr-4 w-6 flex items-center justify-center justify-items-center"
            target="_blank"
          >
            <Patreon class="w-full" color="#fff" />
          </a>
          <a
            v-if="data.socialNetworks.behance"
            class="w-6 flex items-center justify-center justify-items-center"
            :href="data.socialNetworks.behance"
            target="_blank"
          >
            <Behance class="w-full" color="#fff" />
          </a>
        </div>
      </div>

      <div class="mb-10"><ScrollDown /></div>
    </div>
  </div>
</template>

<script>
import Linkedin from '@/components/icons/Linkedin'
import Facebook from '@/components/icons/Facebook'
import Twitter from '@/components/icons/Twitter'
import Instagram from '@/components/icons/Instagram'
import Pinterest from '@/components/icons/Pinterest'
import Youtube from '@/components/icons/Youtube'
import Github from '@/components/icons/Github'
import Gitlab from '@/components/icons/Gitlab'
import Patreon from '@/components/icons/Patreon'
import Behance from '@/components/icons/Behance'
import ScrollDown from '@/components/icons/animated/ScrollDown'

export default {
  components: {
    Linkedin,
    Facebook,
    Twitter,
    Instagram,
    Pinterest,
    Youtube,
    Github,
    Gitlab,
    Patreon,
    Behance,
    ScrollDown
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup() {}
}
</script>
