<template
  ><svg
    version="1.1"
    viewBox="0 0 512 512"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="comp_x5F_144-gitlab">
      <g>
        <path
          :fill="color"
          d="M120.548,48.424c-2.785-7.996-14.104-7.996-16.979,0L52.813,205.457h118.583    C171.306,205.457,120.548,48.424,120.548,48.424z M26.849,284.512c-2.336,7.188,0.271,15.183,6.379,19.765l222.703,165.296    L52.722,205.457L26.849,284.512z M171.306,205.457l84.715,264.115l84.715-264.115H171.306z M485.189,284.512l-25.872-79.055    L256.021,469.572l222.703-165.296C484.92,299.694,487.436,291.699,485.189,284.512z M408.471,48.424    c-2.785-7.996-14.104-7.996-16.979,0l-50.848,157.033h118.584L408.471,48.424z"
        />
      </g>
    </g>
    <g id="Layer_1" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '#F49E25'
    }
  }
}
</script>

<style></style>
